import { Text, tokens } from "@fluentui/react-components"
import { useIntl } from "react-intl"
import { ErrorType, ExpressError } from "../model/ErrorType"
import { FlexContainer } from "../pages/StartUpPage"
import { ErrorCircle16Regular } from "@fluentui/react-icons"
/**
 * Note: Empty string for link url will refresh the panel page.
 */
export const ErrorOverlay = ({ type, message, description, links }: ExpressError) => {
    const intl = useIntl()

    const imgSrc =
        type === ErrorType.Solution || type === ErrorType.Unexpected
            ? "/assets/LoadFailed.png"
            : "/assets/WP_3D_Cube.png"

    return (
        <FlexContainer>
            <img alt="wp-logo" src={imgSrc} height={"140px"} />
            <Text weight="semibold" size={500} style={{ margin: "15px 0px" }}>
                WorkPoint Express 365
            </Text>
            <Text style={{ padding: "5px 10px", textAlign: "center" }}>
                {message ||
                    intl.formatMessage({
                        defaultMessage: "An error occurred",
                        id: "errorOccurred"
                    })}
            </Text>
            {description && (
                <Text style={{ padding: "5px 10px", textAlign: "center" }}>{description}</Text>
            )}
            {links &&
                links.length > 0 &&
                links.map((link) => (
                    <a
                        href={link.url}
                        target={link.url === "" ? "_self" : "_blank"}
                        rel="noreferrer"
                        style={{
                            marginTop: "20px",
                            color:
                                type === ErrorType.Critical
                                    ? tokens.colorPaletteRedForeground1
                                    : tokens.colorNeutralForeground1,
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "underline",
                            cursor: "pointer"
                        }}
                        onClick={link.onClick ? link.onClick : undefined}
                    >
                        {type === ErrorType.Critical && (
                            <ErrorCircle16Regular style={{ marginRight: "6px" }} />
                        )}
                        {link.icon && link.icon}
                        <Text
                            weight="semibold"
                            style={{
                                textAlign: "center",
                                marginLeft: link.icon ? "6px" : undefined
                            }}
                        >
                            {link.text}
                        </Text>
                    </a>
                ))}
        </FlexContainer>
    )
}
